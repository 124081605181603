import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom"; // New line
import ReactGA from "react-ga4";

ReactGA.initialize("G-P9P7FTD4S0");



const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.send({ hitType: "pageview", page: "/", title: "Main Page" });

root.render(
  <React.StrictMode>
  <BrowserRouter>
  <Routes>
          <Route path="/michelle" element={<App />}>
          </Route>
          <Route path="/"  element={<App />}>
          </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
