import logo from './logo.svg';
import './App.css';
import React from 'react';
import { useEffect } from "react"

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import SendIconOutlined from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import IceSkatingOutlinedIcon from '@mui/icons-material/IceSkatingOutlined';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import EmailIconRoundedIcon from '@mui/icons-material/EmailRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://iceskate.coach/">
        Iceskate.Coach
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const defaultTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {

          borderBottom: '2px solid #eee',
          marginBottom: '2rem',
        },
        colorPrimary: {
          backgroundColor: '#fff',
          color: '#777',
        }
      }
    }

  },
  palette: {
    background: {
      default: '#edf1f6'
    }
  },
  typography: {
    fontFamily: 'Montserrat, Roboto+Slab',
    paperchiplabel: {
      fontSize: '.8rem',
      fontWeight: '400',
       } ,
    h1: {
      textAlign: 'left',
      fontSize: '1.5rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h2: {
      textAlign: 'left',
      fontSize: '1rem',
      lineHeight: 1.5,
      fontFamily: 'Roboto Slab',
    },
    h3: {
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 800,
      letterSpacing: 6,
      lineHeight: 2.5,
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',

    },
    body1: {
      
    }
  }

});


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

export default function App() {
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   });
  // };

  useEffect(() => {
    document.title = "iceskate.coach - Michelle"
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>

    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} sx={{mb: .2 }}>
        <Toolbar >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="logo"
            sx={{ mr: 2 }}
          >
            <IceSkatingOutlinedIcon color="primary" />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            ICESKATE<span color="secondary">.</span>COACH / Michelle
          </Typography>
{/* 
          <Stack spacing={2} direction="row">
          <Button variant="outlined">Login</Button>
          </Stack> */}



        </Toolbar>
      </AppBar>
    </Box>

    <Grid container xs={12} sx={{height: {xs: '15rem', md: '25rem'}, mb: '-10rem', backgroundImage: 'url(img/banner.jpg)', backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
       
      </Grid>

    
      <Container component="main" maxWidth="lg" sx={{mt: -1}}>
        <CssBaseline />
        




        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper elevation={0}   sx={{ p: 1, borderTop: '5px solid', borderTopColor: 'secondary.main'}}>
              <Grid container>
                <Grid item xs={2} md={12}>
                      <Box
                  component="img"
                  sx={{
                    height: 'auto',
                    width: '100%',
                  }}
                  alt="Michelle"
                  src="img/profileSquare.jpg"
                />



                </Grid>
                <Grid item xs={10} md={12} sx={{pl: {xs: 2, md: 1}, pt: 0}}>
                        
                        <Typography component="h1" variant="h1">
                            Michelle Madejski
                        </Typography>
                        <Typography component="h2" variant="h2" color="primary">
                            Learn To Skate Instructor
                        </Typography>
                        <Typography sx={{color: '#777', fontSize: '.8rem', pt: 1, pb: 1}}>
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.  */}
                        </Typography>
                        <Paper elevation={0} sx={{mt: 2, p: 1, background: '#edf1f6'}}>

            

                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="paperchiplabel">Teaching Status</Typography>
                            <Chip label="Active" size="small"  color="success" />
                        </Stack>
                        <hr />
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="paperchiplabel">Teaching Since</Typography>
                            <Chip label="January 2023" size="small"  variant="outlined" />
                        </Stack>
                </Paper>


                </Grid>
              </Grid>

          
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>

            <Paper elevation={0}   sx={{p: 2, mb: 1}}>
              <Grid container>



                  <Grid item xs={12} md={6} sx={{mb: 3}}>
                      <Stack direction="row" alignItems="center" gap={1}  sx={{textTransform: 'uppercase', }}>
                        <LocationOnOutlinedIcon color="primary" />
                        <Typography color="primary" sx={{fontSize: '.8rem'}}><b>Rinks</b></Typography>
                      </Stack>
                      <Grid container>
                        <Grid item xs={1} />
                          <Grid item xs={11}>
                            <Typography>Everett Community Ice Rink (Everett Figure Skating Club)</Typography>
                          </Grid>
                      </Grid>
                  </Grid>



                  <Grid item xs={12} md={6} sx={{mb: 3}}>
                      <Stack direction="row" alignItems="center" gap={1}  sx={{textTransform: 'uppercase', }}>
                      <AccessTimeRoundedIcon color="primary"  />
                      <Typography color="primary" sx={{fontSize: '.8rem'}}><b>Private Lesson Rate</b></Typography>
                    </Stack>
                    
                    <Grid container>
                        <Grid item xs={1} />
                          <Grid item xs={11}>
                              <Typography sx={{color: '#444', fontSize: '.9rem'}}>$30 for 30 Minutes</Typography>
                          </Grid>
                    </Grid>
                  </Grid>


                  <Grid item xs={12} md={6} sx={{mb: 3}}>
                      <Stack direction="row" alignItems="center" gap={1}  sx={{textTransform: 'uppercase', }}>
                      <EmailIconRoundedIcon color="primary"  />
                      <Typography color="primary" sx={{fontSize: '.8rem'}}><b>Contact Information</b></Typography>
                    </Stack>
                    
                    <Grid container>
                        <Grid item xs={1} />
                          <Grid item xs={11}>
                              <Typography sx={{color: '#444', fontSize: '.9rem'}}><a href="mailto:michelle@iceskate.coach">michelle@iceskate.coach</a></Typography>
                          </Grid>
                    </Grid>
                  </Grid>



                  <Grid item xs={12} md={6} sx={{mb: 3}}>
                      <Stack direction="row" alignItems="center" gap={1}  sx={{textTransform: 'uppercase', }}>
                      <VerifiedUserRoundedIcon color="primary"  />
                      <Typography color="primary" sx={{fontSize: '.8rem'}}><b>Certification</b></Typography>
                    </Stack>
                    <Grid container>
                        <Grid item xs={1} />
                          <Grid item xs={11}>
                            <Typography sx={{color: '#444', fontSize: '.9rem'}}>SafeSport™ Certified</Typography>
                            <Typography sx={{color: '#444', fontSize: '.9rem'}}>Background Check</Typography>
                            <Typography sx={{color: '#444', fontSize: '.9rem'}}>Learn to Skate Certification</Typography>
                            <Typography sx={{color: '#444', fontSize: '.9rem'}}>CDC HEADS UP to Youth Sports Concussion Training</Typography>
                            <Typography sx={{color: '#444', fontSize: '.9rem'}}>Member of Professional Skaters Association</Typography>
                          </Grid>
                    </Grid>
                  </Grid>
                
              </Grid>
            </Paper>


            
            {/* <Grid container spacing={2}>
              <Grid item xs={12} sx={{mb: 2,  letterSpacing: 2, color: '#aaa', fontStyle: 'italic'}}>
                <Paper variant="outlined"  sx={{p: 2, backgroundColor: 'transparent', }}>
                        Michelle is not currently accepting new students for lessons. A waitlist is not available. Thank you. :)
                    </Paper>
                </Grid>
            </Grid> */}


              <a href="https://forms.gle/GsFbdLhnRTMKiXFt6">
              <Button variant="contained" fullWidth startIcon={<SendIconOutlined />}>
                Get In Touch
              </Button>
              </a>


             <Paper elevation={0}   sx={{p: 2, pt:1, mb: 1, borderBottom: '5px solid', borderBottomColor: '#ddd'}}>
                  <Typography variant="h1"  sx={{textAlign: 'center', m: 2}}>Frequently Asked Questions </Typography>
                  {/* <Typography variant="h3" color="primary" sx={{letterSpacing: 0}}>Why should my child learn to skate?</Typography>
                  <Typography variant="body1" gutterBottom>
                      Ice skating is a life long skill that they can enjoy even as an adult. 
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      There are many options for where a child can go wtih their ice skating including but not limited to hockey, showcase/performances, competitive figure skating, synchronized group skating, ice theater, ice dance, etc.
                  </Typography> */}
                  <Typography variant="h3" color="primary" sx={{letterSpacing: 0}}>How do I get my child started in figure skating?</Typography>
                  <Typography variant="body1" gutterBottom>
                      Kids can begin either in group classes or private lessons with a coach. Group lessons require a 2 month commitment while private lessons can be limited to a single lesson if desired. Private lessons  offer a consistent experience with one coach for tracking your child's progress. Coaches regularly change between the group class sessions. Private lessons are slightly more expensive (roughly $30 per 30 minutes for a private lesson versus $24 for a 30 minute group class) and do not cover ice time or skate rentals. For kids 5 or under, this is $5.50 per skater at the Everett ice rink.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      If you are interested in lessons for your child, please reach out at <a href="mailto:michelle@iceskate.coach">michelle@iceskate.coach</a>.
                  </Typography>
                  <Typography variant="h3" color="primary" sx={{letterSpacing: 0}}>What is the time commitment for figure skating?</Typography>
                  <Typography variant="body1" gutterBottom>
                  For younger kids and beginners, I recommend 30 minutes per week in a lesson and 30 minutes of practice time. Although the practice time is optional, you will want to give your child time on the  ice if you want consistent progress.             
                  </Typography>      
                  
                  <Typography variant="body1" gutterBottom>
                  If you don't see your question answered here, please email me at <a href="mailto:michelle@iceskate.coach">michelle@iceskate.coach</a>.
                  </Typography>
            </Paper>
  
              <a href="https://forms.gle/GsFbdLhnRTMKiXFt6">
              <Button variant="contained" fullWidth startIcon={<SendIconOutlined />}>
                Get In Touch
              </Button>
              </a>
            {/* <Paper elevation={0}   sx={{p: 2, mb: 1, borderBottom: '5px solid', borderBottomColor: '#ddd'}}>
                  <TextField fullWidth id="outlined-basic" sx={{pb: 1}} label="Your Name" variant="outlined" />
                  <TextField fullWidth id="outlined-basic" sx={{pb: 1}} label="Your Email" variant="outlined" />
                  <TextField
                  fullWidth
                    id="outlined-multiline-static"
                    label="Message"
                    multiline
                    sx={{pb: 1}} 
                    rows={4}
                    defaultValue="Anything you'd like to add?"
                    
                  />
                  <Button variant="contained" fullWidth startIcon={<SendIconOutlined />}>
                    Send a Message
                  </Button>
            </Paper> */}



          </Grid>
        </Grid>



        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}



{/* <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box> */}